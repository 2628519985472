<template>
  <aw-contact-us :contacts="contacts" :bordered="isOnWhiteCard" @promotion-interaction="onPromotionInteraction" />
</template>

<script setup>
  import { ref, onMounted } from 'vue';
  import { useNuxtApp } from 'nuxt/app';
  import AwContactUs from '~~/common/components/AwContactUs';
  import analyticsBannerInteractionMixin, {
    sendBannerInteractionAnalytics,
    createThrottledSendBannerViewInteraction,
  } from '~~/common/mixins/analyticsBannerInteractionMixin.js';
  import cmsPageComponentMixin from '~~/common/mixins/cmsPageComponentMixin.js';

  defineOptions({
    inheritAttrs: false,
  });

  const props = defineProps({
    ...cmsPageComponentMixin.props,
    ...analyticsBannerInteractionMixin.props,
  });

  const { $awt } = useNuxtApp();

  const contacts = ref([
    {
      icon: 'mailbox-24',
      title: $awt('aw.cms.contact_us.email.title'),
      href: `mailto:${$awt('aw.global.contact_email')}`,
      label: $awt('aw.global.contact_email'),
    },
    {
      icon: 'answer-machine-24',
      title: $awt('aw.cms.contact_us.phone.title'),
      href: `tel:${$awt('aw.global.contact_phone')}`,
      label: $awt('aw.global.contact_phone_visible'),
    },
  ]);

  onMounted(() => {
    createThrottledSendBannerViewInteraction()(props);
  });

  function onPromotionInteraction (/* idx */) {
    sendBannerInteractionAnalytics(props/* , { title: `aw.cms.contact_us.title ${contacts.value[idx].title}` } */);
  }
</script>
